/*
NAVIGATION BAR
*/
#nav {
  width: 100%;
  padding: 1rem 0; /* Removed horizontal padding */
  height: auto;
}

.nav-center {
  display: flex;
  justify-content: space-between; /* Space between logo and links */
  align-items: center;
  max-width: 1170px;
  margin: 0 auto; /* Center the container */
}

.links ul {
  list-style-type: none;
  display: flex;
  gap: 1.5rem; /* Space between links */
  padding: 0;
  justify-content: flex-end; /* Push ul to the right */
}

.links ul li a {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  color: #333; /* Example text color */
  font-weight: bold;
}

.links ul li a:hover {
  color: #007bff; /* Example hover color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-center {
    flex-direction: column; /* Stack logo and links vertically */
    align-items: flex-start; /* Align items to the start of the container */
    padding: 0 1rem;
  }

  .links ul {
    flex-direction: row;
    width: 100%; /* Make links take full width */
    justify-content: flex-start; /* Align links to the left */
    margin-top: 1rem; /* Space between logo and links */
  }
}

.epa {
  width: 50px;
}

.logo {
  height: 90px;
}

/*
 BIG IMAGE DISPLAY
 */
.bigImgContainer {
  overflow: hidden;
  background-color: rgba(97, 79, 79, 0.701);
  overflow: hidden;
  width: 100%;
  height: 90vh;
}

.bigImgContainer img {
  width: 100%;
  height: 90vh;
  opacity: 30%;
  object-fit: cover;
}

.container-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 80%;
  text-align: center;
  box-sizing: border-box;
}

.container-content h1 {
  font-size: 5vh;
  font-family: "Lato", sans-serif;
  color: white;
  margin: 0;
}

.container-content p {
  font-size: 2.5vh;
  font-family: "Lato", sans-serif;
  color: white;
  margin: 10px 0 0;
}

/* Responsive Design */
@media (max-width: 600px) {
  .container-content h1 {
    font-size: 4vh;
  }

  .container-content p {
    font-size: 1.5vh;
  }
}

@media (max-width: 400px) {
  .container-content h1 {
    font-size: 3vh;
  }

  .container-content p {
    font-size: 1.5vh;
  }
}

/*
PAGE CONTENT
*/
.section {
  display: flex;
  flex-direction: column; /* Stack title and description vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text within the section */
  height: auto;
  /* min-height: 100vh; Optionally make the section fill the viewport height */
}

.section p {
  text-align: left;
  margin: 20px;
}

.title {
  font-size: 2em; /* Adjust as needed */
  font-family: "Lato", sans-serif;
  /* font-family: "Lucida Console", "Courier New", monospace; */
  margin-top: 10px; /* Space between title and image */
  margin-bottom: 10px; /* Space between title and description */
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0.81) , rgba(255, 0, 0, .75),rgba(49, 8, 255, 0.828));
  width: 100%;
  border-radius: 10px;
}

/*
BUTTON STYLES
*/
.button-up {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: rgba(238, 9, 9, 0.631);
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}

.button-up:hover {
  background-color: rgba(238, 9, 9, 0.9);
}

/* 
INFO BOX CONTAINER
*/

.infoBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding: 20px;
}

.infoBoxContainer div {
  flex: 1;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box; /* Ensure padding is included within height */
  margin: 20px;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.341);
}

.infoBoxContainer div h1 {
  color: rgb(226, 70, 70);
  font-size: 5vw;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.infoBoxContainer ul li {
  color: rgb(0, 0, 0);
  text-align: left;
  margin-left: -2rem;
  margin-bottom: 30px;
  font-family: "Lato", sans-serif;
  font-size: 2vh;
}

@media (max-width: 768px) {
  .infoBoxContainer {
    flex-direction: column;
  }

  .infoBoxContainer div {
    margin: 10px 0;
  }
}

@media (max-width: 400px) {
  .infoBoxContainer div h1 {
    font-size: 4rem;
  }

  .infoBoxContainer ul li {
    font-size: 1.5rem;
  }
}

.contact-us-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}