/*

*/
.logo {
  height: 80px;
}

.links-container {
  overflow: hidden;
  transition: var(--transition);
}

.show-links {
  height: 200px;
}

.links a {
  background: var(--clr-white);
  color: var(clr-grey-5);
  font-size: 1.1rem;
  letter-spacing: var(--spacing);
  display: block;
  transition: var(--transition);
  font-weight: bold;
  padding: 0.75rem 0;
}

/*
 style sheet for big image display
 */
.bigImgContainer {
  overflow: hidden;
  background-color: rgba(131, 107, 107, 0.701);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.bigImgContainer img {
  width: 100%;
  height: 100%;
  opacity: 30%;
  object-fit: cover;
}

.bigImgContainer div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(0, 0, 0);
  font-size: 2rem;
  text-align: center;
  z-index: 1;
}

/* 
Code for the infoBoxContainer
*/

.infoBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
}

.infoBoxContainer div {
  flex: 1;
  background-color: #b8b8be8a;
  margin: 20px;
  border-radius: 20px;
  padding: 20px;
}

.infoBoxContainer div h1 {
  color: rgb(0, 0, 0);
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.infoBoxContainer div p {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}